@import "../../../less/_mixins/typography.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/general.less";
@import "../../../less/helpers.less";

.accordion {
	margin: 16px 0;
	background: @white;
	font-family: @fontFamilyStandard-theme-cosma;
	font-size: @fontSizeStandard;
}

.accordion-icon {
	position: absolute;
	top: calc(50% - 0.5em);
	right: 8px;
	width: 1em;
	height: 1em;
	display: inline-block;
	transition: transform 0.2s ease-out;
	transform: rotate(0);
	font-size: 1.6rem;
	color: @textColorStandard;
}

.accordion-icon.expanded {
	transform: rotate(180deg);
}

.accordion-header {
	position: relative;
	line-height: 1.8rem;
	font-weight: 600;
	padding: 16px 32px 16px 8px;
	background-color: @white;
	cursor: pointer;
	user-select: none;
	&:not(.expanded) {
		border-bottom: 1px solid @gray300;
	}

	&:hover, &:focus {
		background-color: @gray300;
	}

	&.expanded:hover, &.expanded:focus {
		background: @white;

	}
}

.accordion-panel {
	transition: all 0.2s ease-out;
	max-height: 0;
	overflow: hidden;
	> div {
		background: @white;
		padding: 8px 32px 8px 8px;
	}
}

.accordion-panel.expanded {
	max-height: 1000px;
	transition: all 0.4s ease-out;
	padding-bottom: 8px;

	&--with-closing-line {
		border-bottom: 1px solid @gray300;
	}
}
