@import "../../../../less/_variables/color.less";

@dotSize: 8px;
@dotsVisible: 5;

//TODO - Remove this once the token is available
@borderRadius: 8px;

.dot-pagination {
	display: flex;
	max-width: calc((@dotSize + 8px) * @dotsVisible);
	height: 16px;
	overflow: hidden;
	border-radius: @borderRadius;

	.dots-container {
		height: inherit;
		display: flex;
		transition: transform 0.3s ease;
		align-items: center;
		justify-content: flex-start;
	}

	.dot {
		height: @dotSize;
		aspect-ratio: 1;
		border-radius: 50%;
		margin: 0 4px;
		border-width: 0px;
		cursor: pointer;
		transition: transform 0.3s ease;
		padding-inline: 0px;
		padding-block: 0px;
	}

	&.variant-default {
		.dot {
			background-color: @gray300;

			&.active {
				background-color: @gray1000;
			}
		}
	}

	&.variant-on-image {
		.dots-container {
			background-color: #ffffff33;
		}
		.dot {
			background-color: @gray100;
			opacity: 0.5;

			&.active {
				background-color: @gray100;
				opacity: 1;
			}
		}
	}
}
