@import "../../../less/_variables/color.less";
@import "../../../less/_variables/typography.less";

.autocomplete {
	position: relative;

	&-menu {
		width: 100%;
		padding: 4px 0;
		position: absolute;
		background-color: @gray100;
		border: 1px solid @gray700;
		box-shadow: 0px 4px 16px 0px #0000001a;
		border-radius: 0 0 4px 4px;
		font-family: @fontFamilyStandard-theme-cosma;
	}

	&-category {
		padding: 8px 12px 0;
		color: @gray850;
		border-top: 1px solid @gray300;
		margin-top: 10px;

		&:first-child {
			margin-top: 0;
			border-top: none;
		}
	}

	&-item {
		padding: 8px 12px;
		cursor: pointer;

		&-highlighted {
			background-color: @gray300;
		}
	}

	&-input-open {
		border-radius: 4px 4px 0 0 !important; // Override Input's border-radius
	}

	&-clear {
		width: 16px !important;
		height: 16px !important;
		top: 0 !important;
	}

	&-loading {
		padding: 8px 12px;
		color: @gray850;
		display: flex;
		gap: 8px;
		align-items: center;
	}
}
